import TtgApi from '../../services/api.ttg.js';

const state = {
  loading: false,
  errorMessage: '',
  vehicles: [],
};

const mutations = {};

const actions = {
  getVehicles({ state, commit, rootState }) {
    state.loading = true;
    state.vehicles = [];
    state.errorMessage = null;
    
    return new Promise((resolve, reject) => {
      TtgApi.get(`/cpregistrationbyemail`)
        .then(res => {
          state.loading = false;
          if (!res) throw Error('res is null');
          state.vehicles = res.data;
          // state.regId = res.data.RegistrationID;
          // console.log('res.data', res.data);
          resolve(`Vehicles retreived`);
        }).catch(err => {
          // console.log('in catch of api call in dashboard store', err);
          state.loading = false;
          if(err && err.response && err.response.data) {
            state.errorMessage = err.response.data;
          } else if(err && err.response) {
            state.errorMessage = err.response;
          } else {
            state.errorMessage = err;
          }
          return reject('test');
        });
    });
  },
  createRegistration({ dispatch }, passed) {
    return new Promise((resolve, reject) => {
      const payload = {
        VehicleYear: passed.year,
        VehicleMake: passed.make,
        VehicleModel: passed.model,
      };

      TtgApi.post(`/cpregistration`, payload)
        .then(res => {
          // console.log(res.data);
          const regId = res.data.ID[0];
          resolve({regId, msg: `New Registration created for ${passed.year} ${passed.make} ${passed.model}`});
        }).catch(err => {
          console.log(err);
          reject(`Sorry, we were unable to create your registration at this time`);
        });
    });
  },
  
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
