import TtgApi from '../../services/api.ttg.js';

const state = {
  loading: false,
};

const mutations = {};

const actions = {
  pageViewed({ state }, regId) {
    state.downloadUrl = null;
    return new Promise((resolve, reject) => {
      TtgApi.put(`cpregistration/CostBreakdown/${regId}`, {})
        .then(res => {
          resolve('cost-breakdown page viewed');
        })
        .catch(err => {
          console.log('error setting cost-breakdown viewed', err);
          reject('error setting cost-breakdown viewed');
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
