import VueRouter from '../../router';
import token from '../../services/token';
import TtgApi from '../../services/api.ttg.js';
import vuetify from '../../plugins/vuetify';

const localStorageDarkMode = 'dark_mode';

const state = {
  app: {
    name: process.env.VUE_APP_TITLE,
    // version: 0, //JSON.parse(unescape(process.env.PACKAGE_JSON || '%7Bversion%3A0%7D')).version,
  },
  entryRoute: null,
  title: 'test',
  paymentAmount: 0,
  amountToCharge: 0,
  userData: {
    isAuthed: false,
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: '',
    vin: null,
    name: null,
    id_token: null,
    UserID: null,
    RegistrationID: null,
    darkModeOn: true,
  },
  stepOne: {
    fullName: '',
    email: '',
    phoneNumber: '',
    billingEqualToMailing: false,
    mailingAddressOne: '',
    mailingAddressTwo: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    billingAddressOne: '',
    billingAddressTwo: '',
    billingCity: '',
    billingState: '',
    billingZipCode: '',
    paymentType: '',
    taxableAmount: '',
    deliveryDate: '',
  },
};

// method called to cofigure initial state
function setInitialUserState() {
  console.log('');
  console.log('   TTG Customer Portal');
  console.log('--------------------------');
  console.log('> setting up initial state');

  const localIdToken = localStorage.getItem('id_token');
  const localEmail = localStorage.getItem('email');
  const localRegId = localStorage.getItem('reg_id');
  const localVin = localStorage.getItem('vin');
  const localUserId = localStorage.getItem('user_id');

  // catch the first time a user uses the app
  let darkMode = localStorage.getItem(localStorageDarkMode)
  if (darkMode === null) {
    darkMode = 'true';
  }
  const darkModeBoolean = (darkMode === 'true');
  state.userData.darkModeOn = darkModeBoolean;
  vuetify.framework.theme.dark = darkModeBoolean;
  
  if (localIdToken && !token.isExpired()) {
    console.log('> token is valid');

    // need to validate the JWT expiration time
    state.userData.isAuthed = true;
    state.userData.email = localEmail;
    state.userData.vin = localVin;
    state.userData.id_token = localIdToken;
    state.userData.UserID = localUserId;
    state.userData.RegistrationID = localRegId;
    state.entryRoute = null;

    const userData = token.getUserData();
    // console.log('userdata', userData);
    state.userData.email = userData.email;
    state.userData.firstName = userData.firstName;
    state.userData.lastName = userData.lastName;
    state.userData.phoneNumber = userData.phoneNumber;
  } else {
     console.log('>> VueRouter.currentRoute.name: ', VueRouter.history._startLocation);
   // state.entryRoute = VueRouter.history._startLocation;
    state.entryRoute = "/app/dashboard";

    console.log('> the token is not valid');
  }
}

setInitialUserState();

const mutations = {
  setUserIsAuthed(state) {
    // console.log('in app/setUserIsAuthed');
    state.userData.isAuthed = true;
  },
  setUserEmail(state, email) {
    state.userData.email = email;
  },
  setUserFirstName(state, firstName) {
    state.userData.firstName = firstName;
  },
  setUserLastName(state, lastName) {
    state.userData.lastName = lastName;
  },
  setTitle(state, payload) {
    state.title = payload.title;
  },
  toggleDarkMode(state, payload) {
    const mode = !state.userData.darkModeOn;
    state.userData.darkModeOn = mode;
    vuetify.framework.theme.dark = mode;
    localStorage.setItem(localStorageDarkMode, mode);
  },
};

const actions = {
  
  // step 1
  updateStepOne_fullName({ state }, newValue) {
    state.stepOne.fullName = newValue;
  },
  updateStepOne_email({
      state
    }, newValue) {
    state.stepOne.email = newValue;
  },
  updateStepOne_phone({ state }, newValue) {
    state.stepOne.phoneNumber = newValue;
  },
  updateStepOne_mailingAddressOne({ state }, newValue) {
    state.stepOne.mailingAddressOne = newValue;
  },
  updateStepOne_mailingAddressTwo({ state }, newValue) {
    state.stepOne.mailingAddressTwo = newValue;
  },
  updateStepOne_mailingCity({ state }, newValue) {
    state.stepOne.mailingCity = newValue;
  },
  updateStepOne_mailingState({ state }, newValue) {
    state.stepOne.mailingState = newValue;
  },
  updateStepOne_mailingZipCode({ state }, newValue) {
    state.stepOne.mailingZipCode = newValue;
  },
  checkUserAuth({ state }) {
    // console.log(':: isAuthed from state: ' + state.userData.isAuthed);
    return !!state.userData.isAuthed;
  },
  logUserOut({ state }, errorMsg) {
    // console.log(':: in logUserOut action in store');
    localStorage.removeItem('id_token');
    localStorage.removeItem('email');
    // state.userData.forEach(item => item = null);
    state.userData = {
      isAuthed: false,
      email: null,
      firstName: null,
      lastName: null,
      phoneNumber: '',
      vin: null,
      name: null,
      id_token: null,
      UserID: null,
      RegistrationID: null,
    };
    // console.log('> user logged out');
    
    if(VueRouter.currentRoute.name !== 'SignIn' && VueRouter.currentRoute.name !== 'Home') {
      VueRouter.push({ name: 'SignIn', params: { errorMsg } })
        .catch(err => {
          // this catches if you are on the sign in page already
          if (err.name === 'NavigationDuplicated') console.log('on sign-in already');
        });
    }
    return true;
  },
  signIn({ state, commit }, body) {
    return new Promise((resolve, reject) => {
      const payload = {
        Username: body.Username,
        Password: body.Password,
      };
      TtgApi.post('/cpuser/login', payload).then(res => {
        // console.log('userData', userData);
        state.userData.isAuthed = true;
        state.userData.email = body.Username;
        state.userData.id_token = res.data.id_token;
        localStorage.setItem('id_token', res.data.id_token);
        localStorage.setItem('email', body.Username);
        const userData = token.getUserData();
        state.userData.firstName = userData.firstName;
        state.userData.lastName = userData.lastName;

        if(state.entryRoute){
          commit('snackmsg/show', { text: `Welcome ${body.Username}`}, {root: true});
          VueRouter.push({ path: state.entryRoute })
          .catch(err => {
            // this catches if you are on the sign in page already
            if (err.name === 'NavigationDuplicated') {
            // console.log('on sign-in already');
              VueRouter.push({ name: 'app.dashboard' });
            }
          });
        } else {
          VueRouter.push({
            name: 'app.dashboard',
            params: { successMsg: `Welcome back ${body.Username}` },
          });
          commit('snackmsg/show', { text: `Welcome ${body.Username}` }, {root: true});
        }
        state.entryRoute = null;
        
        // resolve('User signed in: ' + body.Username);
      }).catch(err => {
        // console.log('signIn err');
        console.log(err);
        state.userData.isAuthed = false;
        reject(err);
      });
    });
  },
  profile_getUserData({ state }, body) {
    const userData = token.getUserData();
    state.userData.email = userData.email;
    state.userData.firstName = userData.firstName;
    state.userData.lastName = userData.lastName;
    state.userData.phoneNumber = userData.phoneNumber;
  },
};

const getters = {
  getStep: (state) => state.step,
  getTitle: (state) => state.title,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
