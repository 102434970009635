import TtgApi from '../../services/api.ttg.js';

const state = {
  loading: false,
};

const mutations = {};

const actions = {
  save({ state }, passed) {
    const payload = {
      PaymentType: passed.paymentType,
      ReferenceNumber:passed.referenceNumber,
    };

    return new Promise((resolve, reject) => {
      TtgApi.put(`cpregistration/PaymentInfo/${passed.regId}`, payload)
        .then(res => {
          resolve('PaymentInfo updated');
        })
        .catch(err => {
          console.log('error setting PaymentInfo', err);
          reject('error setting PaymentInfo');
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
