import TtgApi from '../../services/api.ttg.js';
import ApiAws from '../../services/api.aws.js';

const state = {
  loading: false,
  awsUploadUrl: '',
  downloadUrl: null,
  awsUploadUrl2: '',
  downloadUrl2: null,
};

const mutations = {};

const actions = {
  getCurrent({ state }, passed) {
    state.downloadUrl = null;
    return new Promise((resolve, reject) => {
      TtgApi.get(`cpdownloadurl/TT_Proforma/${passed.regId}/${passed.bosFileType}`)
        .then(res => {
          state.downloadUrl = res.data.url[0];
          resolve('current BOS set');
        })
        .catch(err => {
          console.log('error getting the download url', err);
          reject('error getting the download url');
        });
    });
  },
  getCurrent2({ state }, passed) {
    state.downloadUrl2 = null;
    return new Promise((resolve, reject) => {
      TtgApi.get(`cpdownloadurl/TT_Proforma2/${passed.regId}/${passed.bosFileType2}`)
        .then(res => {
          state.downloadUrl2 = res.data.url[0];
          resolve('current BOS set');
        })
        .catch(err => {
          console.log('error getting the download url', err);
          reject('error getting the download url');
        });
    });
  },
  getAwsUploadUrl({ state }, regId) {
    return new Promise((resolve, reject) => {
      TtgApi.get(`/cpuploadurl/TT_Proforma/${regId}/pdf`)
        .then(res => {
          if (res.data && res.data.url) {
            state.awsUploadUrl = res.data.url[0];
            resolve(`registration record for ${regId} done`);
          } else {
            reject('no url returned');
          }
        }).catch(err => {
          console.log(err);
          reject(`We were unable to fetch the AWS url for ${regId}`);
        });
    });
  },
  clearBos({ state }) {
    state.downloadUrl = null;
  },
  uploadDoc({ state, dispatch }, passed) {
    const type = passed.file.type;
    const splitType = type.split('/');
    const fileType = splitType[1];
    const checkForPlus = fileType.split('+');
    const finalType = checkForPlus[0];

    return new Promise((resolve, reject) => {
      if (!passed.regId) reject('the record id was not passed');
      if (!finalType) reject('the file you are uploading does not have a valid type');

      // get the aws url to upload
      TtgApi.get(`/cpuploadurl/TT_Proforma/${passed.regId}/${finalType}`)
        .then(res => {
          if (res.data && res.data.url) {
            // console.log('1> upload url: '+res.data.url[0]);
            const awsUploadUrl = res.data.url[0];
            const formData = new FormData();
            formData.append('file', passed.file);

            // upload the file to aws
            ApiAws.put(awsUploadUrl, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(function () {
                // console.log('2> file uploaded');

                // update the file type in the ttg api
                TtgApi.put(`/cpregistration/BosInfo/${passed.regId}`, {
                    BoSFileType: finalType
                  })
                  .then(res => {
                    // console.log('3> updated file type');

                    // get the url to preview the file
                    TtgApi.get(`cpdownloadurl/TT_Proforma/${passed.regId}/${finalType}`)
                      .then(res => {
                        // console.log('4> url to download image');
                        // console.log(res.data.url[0]);
                        state.downloadUrl = res.data.url[0];
                        dispatch("registration/setActiveRecord", passed.regId, {
                            root: true
                          })
                          .then(res => {
                            // console.log('>>> ', res);
                            resolve('file uploaded');
                          })
                          .catch(err => {
                            console.log('error retreiving vehicle details', err);
                            reject('error retreiving vehicle details');
                          })
                      })
                      .catch(err => {
                        console.log('error getting the download url', err);
                        reject('error getting the download url');
                      });
                  })
                  .catch(err => {
                    console.log('update file type error', err);
                    reject('update file type error');
                  });
              })
              .catch(err => {
                console.log('error uploading the file to aws', err);
                reject('error uploading the file to aws');
              });
          } else {
            reject('no upload url returned');
          }
          // state.detailsLoading = false;
        }).catch(err => {
          console.log('error getting the url to upload');
          console.log(err);
          reject(`We were unable to fetch the AWS url for ${passed.regId}`);
        });
    });
  },
  uploadDoc2({ state, dispatch }, passed) {
    const type = passed.file.type;
    const splitType = type.split('/');
    const fileType = splitType[1];
    const checkForPlus = fileType.split('+');
    const finalType2 = checkForPlus[0];

    return new Promise((resolve, reject) => {
      if (!passed.regId) reject('the record id was not passed');
      if (!finalType2) reject('the file you are uploading does not have a valid type');

      // get the aws url to upload
      TtgApi.get(`/cpuploadurl/TT_Proforma2/${passed.regId}/${finalType}`)
        .then(res => {
          if (res.data && res.data.url) {
            // console.log('1> upload url: '+res.data.url[0]);
            const awsUploadUrl2 = res.data.url[0];
            const formData2 = new FormData();
            formData2.append('file', passed.file);

            // upload the file to aws
            ApiAws.put(awsUploadUrl2, formData2, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(function () {
                // console.log('2> file uploaded');

                // update the file type in the ttg api
                TtgApi.put(`/cpregistration/BosInfo/${passed.regId}`, {
                    BoSFileType2: finalType
                  })
                  .then(res => {
                    // console.log('3> updated file type');

                    // get the url to preview the file
                    TtgApi.get(`cpdownloadurl/TT_Proforma2/${passed.regId}/${finalType}`)
                      .then(res => {
                        // console.log('4> url to download image');
                        // console.log(res.data.url[0]);
                        state.downloadUrl2 = res.data.url[0];
                        dispatch("registration/setActiveRecord", passed.regId, {
                            root: true
                          })
                          .then(res => {
                            // console.log('>>> ', res);
                            resolve('file uploaded');
                          })
                          .catch(err => {
                            console.log('error retreiving vehicle details', err);
                            reject('error retreiving vehicle details');
                          })
                      })
                      .catch(err => {
                        console.log('error getting the download url', err);
                        reject('error getting the download url');
                      });
                  })
                  .catch(err => {
                    console.log('update file type error', err);
                    reject('update file type error');
                  });
              })
              .catch(err => {
                console.log('error uploading the file to aws', err);
                reject('error uploading the file to aws');
              });
          } else {
            reject('no upload url returned');
          }
          // state.detailsLoading = false;
        }).catch(err => {
          console.log('error getting the url to upload');
          console.log(err);
          reject(`We were unable to fetch the AWS url for ${passed.regId}`);
        });
    });
  }
};

const getters = {
  downloadUrl: state => state.downloadUrl,
  downloadUrl2: state => state.downloadUrl2,

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
