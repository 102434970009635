import TtgApi from '../../services/api.ttg.js';

const state = {
  loading: false,
  ttgBaseUiUrl: TtgApi.getBaseUiUrl(),
};

const mutations = {};

const actions = {};

const getters = {
  forgotPasswordUrl: state => {
    const forgotUrl = state.ttgBaseUiUrl + '/forgotpassword';  // eg: https://ttg-qa.thetitlegirl.com/forgotpassword
    return forgotUrl;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
