import TtgApi from '../../services/api.ttg.js';
import IpApi from '../../services/api.ipify.js';
import CryptoService from "../../services/crypto.js";

const crypto = new CryptoService();

const state = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  passwordConfirm: '',
};

const mutations = {};

const actions = {
  storePartialForm({ state, commit }, formObj) {
    const payload = {
      Firstname: formObj.firstName,
      Lastname: formObj.lastName,
      EmailAddress: formObj.email,
      IPAddress: null,
      PhoneNumber: formObj.phoneNumber,
    };

    return new Promise((resolve, reject) => {
      IpApi.get('', {params: { format: 'json'}}) // '?format=jsonp&callback=getIP'
        .then(res => {
          // console.log((res.data.ip));
          payload.IPAddress = res.data.ip;
          TtgApi.post('/cppendinguser/create', payload)
            .then(res => {
              // console.log(res.data);
              resolve('Account created for ' + payload.EmailAddress);
            }).catch(err => {
              console.log(err);
              reject(`We were unable to create a pending account for ${payload.EmailAddress}. ${err}`);
            });
        });
    });
  },
  createAccount({ state, commit }, formObj) {
    const payload = {
      Firstname: formObj.firstName,
      Lastname: formObj.lastName,
      Username: formObj.email,
      Phonenumber: formObj.phoneNumber,
      Password: crypto.getEncryptedPwd(formObj.password),
      passwordConfirm: crypto.getEncryptedPwd(formObj.confirmPassword),
      TandCReviewed: formObj.termsTimestamp,
    };

    return new Promise((resolve, reject) => {
      TtgApi.post('/cpuser/create', payload)
        .then(res => {
          // state.userData.isAuthed = true;
          // state.userData.email = body.Username;
          // state.userData.vin = body.VIN;
          // state.userData.UserID = res.data.userID;
          // state.userData.RegistrationID = res.data.RegistrationID;
          // console.log(res.data);
          localStorage.setItem('id_token', res.data.id_token);
          localStorage.setItem('email', payload.Username);
          commit('app/setUserIsAuthed', null, {root: true});
          commit('app/setUserEmail', payload.Username, {root: true});
          commit('app/setUserFirstName', payload.Firstname, {root: true});
          commit('app/setUserLastName', payload.Lastname, {root: true});
          resolve('Account created for ' + payload.Username);
        }).catch(err => {
          // state.userData.isAuthed = false;
          console.log(err);
         // reject(`There is already an account for ${payload.Username}. Please click "Forgot your Password" to reset your password.  ${err}`);
         reject(`There is already an account for ${payload.Username}. Please click "Forgot your Password" to reset your password.`);

        });
    });
  },
};

const getters = {
  getStep: (state) => state.step,
  getTitle: (state) => state.title,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
