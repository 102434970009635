import store from '../store';

const publicRoutes = [{
  path: '/',
  name: 'Home',
  component: () => import('../views/public/home/Home.vue'),
}, 
{
  path: '/tesla',
  name: 'HomeTesla',
  component: () => import('../views/public/home/HomeTesla.vue'),
},
{
  path: '/about',
  name: 'About',
  component: () => import( /* webpackChunkName: "About" */ '../views/public/About.vue'),
}, {
  path: '/terms-and-conditions',
  name: 'TermsAndConditions',
  component: () => import( /* webpackChunkName: "TermsAndConditions" */ '../views/public/TermsAndConditions.vue'),
}, {
  path: '/create-account',
  name: 'CreateAccount',
  component: () => import( /* webpackChunkName: "CreateAccount" */ '../views/public/CreateAccount.vue'),
}, {
  path: '/sign-in',
  name: 'SignIn',
  props: true,
  component: () => import( /* webpackChunkName: "SignIn" */ '../views/public/SignIn.vue'),
  beforeEnter: (to, from, next) => {
    store.dispatch('app/checkUserAuth')
      .then(authed => {
        authed ? next({
          name: 'app.dashboard'
        }) : next();
      })
  },
}, {
  path: '*',
  redirect: '/sign-in'
}];

export default publicRoutes;
