import TtgApi from '../../services/api.ttg.js';
import ApiAws from '../../services/api.aws.js';

let pollInterval = null;
const pollRate = 30000;

const state = {
  loading: false,
  detailsLoading: false,
  errorMessage: '',
  vehicles: [],
  activeVinDetails: defaultRegRecord,
  vinProgress: {},
  regId: null,
  activeVin: null,
  app: null,
};

const defaultRegRecord = {
  ActualRegistrationAmount: null,
  BOSFileType: null,
  BOSUploaded: null,
  CPRequestSubmittedOn: null,
  ClientTrackingNumber: null,
  County: null,
  CountyFee: null,
  CustomerName: null,
  EmailAddress: null,
  File_Whiteslip: null,
  LastUpdatedBy: null,
  LateFee: null,
  PaymentType: null,
  PhoneNumber: null,
  RegistrationAmount: null,
  RegistrationID: null,
  SalesTax: null,
  ServiceFee: null,
  ShippingAddrLine1: null,
  ShippingAddrLine2: null,
  ShippingCity: null,
  ShippingState: null,
  ShippingZipCode: null,
  Status: null,
  TaxableAmount: null,
  TitleDOS: null,
  VIN: null,
  VehicleMake: null,
  VehicleModel: null,
  VehicleYear: null,
  WhiteslipAmount: null,
  Whiteslipcompletedate: null,
  ZipCode: null,
  fedExTrackingUrl: null,   // not in response, is created on setActiveRecord response
  receiptDownloadUrl: null, // not in respone, it is create don setActiveRecord response
  yearMakeModel: null,      // not in response
  lastSixOfVin: null,       // not in response
  lastName: null,           // not in response
}

const mutations = {};

const actions = {
  storePartialApp({
    state,
    dispatch
  }, payload) {
    return new Promise((resolve, reject) => {
      const transform = {
        VehicleYear: payload.year,
        VehicleMake: payload.make,
        VehicleModel: payload.model,
        CustomerName: payload.fullName,
        PhoneNumber: payload.phoneNumber,
        ShippingAddrLine1: payload.addressOne,
        ShippingAddrLine2: payload.addressTwo,
        ShippingCity: payload.city,
        ShippingState: payload.state,
        ShippingZipCode: payload.zip,
      };

      TtgApi.put(`/cpregistration/shippinginfo/${payload.regId}`, transform)
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
    });
  },
  submitApplication({
    state,
    dispatch,
    rootState
  }, passed) {
    const regId = passed.regId;
    const payload = {
      VehicleYear: passed.app.year,
      VehicleMake: passed.app.make,
      VehicleModel: passed.app.model,
      CustomerName: passed.app.fullName,
      PhoneNumber: passed.app.phoneNumber,
      ShippingAddrLine1: passed.app.addressOne,
      ShippingAddrLine2: passed.app.addressTwo,
      ShippingCity: passed.app.city,
      ShippingState: passed.app.state,
      ShippingZipCode: passed.app.zip,
      BOSFileType: passed.app.bosFileType,
      EmailAddress: rootState.app.userData.email,
      // CPRequestSubmittedOn: new Date().toLocaleString(),
    }
    // console.log(payload);
    // const acceptFileTypes = ['pdf', 'png'];

    return new Promise((resolve, reject) => {
      if (!regId) return reject('Unable to update your information at this time');
      // if (!acceptFileTypes.includes(payload.BOSFileType)) reject('the file you are uploading does not have the right type');

      TtgApi.get(`/cpuploadurl/TT_Proforma/${passed.regId}/${passed.app.bosFileType}`)
        .then(res => {
          if (res.data && res.data.url) {
            // console.log('1> got the upload url: ' + res.data.url[0]);
            const awsUploadUrl = res.data.url[0];
            const formData = new FormData();
            formData.append('file', passed.bosFile);
            const options = {
              headers: {
                'Content-Type': passed.bosFile.type
              }
            };
            // {
            //   headers: {
            //     'Content-Type': 'multipart/form-data'
            //   }
            // }
            // upload the file to aws
            ApiAws.put(awsUploadUrl, passed.bosFile, options).then(function () {
                // console.log('2> file uploaded to AWS');

                // update the file type in the ttg api
                TtgApi.put(`/cpregistration/submitcprequest/${regId}`, payload)
                  .then(res => {
                    // console.log(res.data);
                    // console.log('3> updated file type');

                    // get the url to preview the file
                    TtgApi.get(`cpdownloadurl/TT_Proforma/${passed.regId}/${passed.app.bosFileType}`)
                      .then(res => {
                        // console.log('4> url to download image');
                        // console.log(res.data.url[0]);
                        state.downloadUrl = res.data.url[0];
                        dispatch("registration/setActiveRecord", passed.regId, {
                            root: true
                          })
                          .then(res => {
                            // console.log('>>> ', res);
                            resolve('file uploaded and application submitted');
                          })
                          .catch(err => {
                            console.log('error retreiving vehicle details', err);
                            reject('error retreiving vehicle details');
                          })

                      })
                      .catch(err => {
                        console.log('error getting the download url', err);
                        reject('error getting the download url');
                      });
                  })
                  .catch(err => {
                    console.log('update file type error', err);
                    reject('update file type error');
                  });
              })
              .catch(err => {
                console.log('error uploading the file to aws', err);
                reject('error uploading the file to aws');
              });
          } else {
            reject('no upload url returned');
          }
          // state.detailsLoading = false;
        }).catch(err => {
          console.log('error getting the url to upload');
          console.log(err);
          reject(`We were unable to fetch the AWS url for ${passed.regId}`);
        });
    }).catch(err => {
      state.errorMessage = err.response.data;
      console.log(err.response);
      return reject(`We were unable to update the addresses for ${regId}`);
    });
  },
  getReceiptDownloadUrl({}, regId) {
    return new Promise((resolve, reject) => {
      TtgApi.get(`/cpdownloadurl/Reg_Whiteslip/${regId}/pdf`)
        .then(res => {
          // console.log('>> whiteslip', res.data.url[0]);
          return resolve(res.data.url[0]);
        })
        .catch(err => {
          console.log('getReceiptDownloadUrl error', err);
          reject(`registration record for ${regId} and no receipt`);
        });
    });
  },
  setActiveRecord({
    state,
    dispatch
  }, regId) {
    // state.detailsLoading = true;
    state.app = defaultRegRecord;
    //dispatch('billOfSale/clearBos', null, { root: true});
    return new Promise((resolve, reject) => {
      TtgApi.get(`/cpregistration/${regId}`)
        .then(res => {
          if (res.data.length === 1) {
            // console.log(res.data[0]);
            state.app = res.data[0];
            state.app.yearMakeModel = res.data[0].VehicleYear + ' ' + res.data[0].VehicleMake + ' ' + res.data[0].VehicleModel;
            state.app.lastSixOfVin = res.data[0].VIN ? res.data[0].VIN.slice(res.data[0].VIN.length - 6) : null;
            // set last name
            // set ymm

            const trackingNumber = res.data[0].ClientTrackingNumber;
            if (trackingNumber) {
              state.app.fedExTrackingUrl = "https://www.fedex.com/fedextrack/?trknbr=" + res.data[0].ClientTrackingNumber;
              TtgApi.get(`/cpdownloadurl/Reg_Whiteslip/${regId}/pdf`)
                .then(res => {
                  // console.log('>> whiteslip', res.data.url[0]);
                  state.app.receiptDownloadUrl = res.data.url[0];
                  resolve(`registration record for ${regId} and receipt`);
                })
                .catch(err => {
                  console.log('whiteslip error', err);
                  resolve(`registration record for ${regId} and no receipt`);
                });
            } else {
              resolve(`registration record for ${regId} and no tracking number`);
            }

          } else {
            reject('no records returned');
          }
          state.detailsLoading = false;
        }).catch(err => {
          console.log(err);
          console.log(err.errors[0]);
          state.detailsLoading = false;
          let errorMsg = `We were unable to fetch the registration for ${regId}`;
          if (err.errors) {
            errorMsg = err.errors[0];
          }
          reject(errorMsg);
        });
    });
  },
  slowPoller({
    state,
    dispatch
  }) {
    const regId = state.app.RegistrationID;
    // console.log('slow Poller started: ',regId);
    pollInterval = setInterval(() => {
      dispatch('setActiveRecord', state.app.RegistrationID)
        .then(res => {
          // console.log('poller done');
        })
        .catch(err => {
          console.log('poller error: ', err);
        });
    }, pollRate);
  },
  stopSlowPoller({
    state
  }) {
    clearInterval(pollInterval);
  },
  getRegistrationProgress({
    state
  }, regId) {
    // console.log('getRegistrationProgress action called');
    state.vinProgress = [];
    // setTimeout(() => {
    // state.vinProgress = vinProgressMockObj;
    // }, 700);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
