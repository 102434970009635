import axios from 'axios';
import token from './token.js';
import store from '../store';
import TtgApi from './api.ttg.js';


const baseTtgUiUrl = process.env.VUE_APP_TTG_UI_URL;
const baseTtgApiUrl = process.env.VUE_APP_TTG_API_URL;
const baseAchUrl = process.env.VUE_APP_ACH_URL;


// @todo: show correct error message on timeout
const AchApi = axios.create({
  baseURL: baseAchUrl,
  //baseURL: baseTtgApiUrl,
  timeout: 10000,
  headers: {
    'X-App-Name': 'TTG Customer Portal',
    'Access-Control-Allow-Origin': '*' ,
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept ',
    'Access-Control-Allow-Methods': "POST, GET, PUT, OPTIONS, DELETE" ,
    'Access-Control-Max-Age': 3600 
  }
});

AchApi.interceptors.request.use(function (config) {
  const id_token = token.getToken();
  // @todo: check for no token present
  if (id_token) {
    config.headers.Authorization = id_token;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

TtgApi.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx are caught here
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx are caught here
  if (error.response.status === 401) {
    const tokenIsExpired = token.isExpired();
    if (tokenIsExpired) {
      store.dispatch('app/logUserOut', 'Your token has expired, please login');
    }
  } else {
     //return Promise.reject('test error'+error);
  }
  return Promise.reject(error.response.data);
});

AchApi.getBaseApiUrl = function () {
  console.log("url is" + baseAchUrl);
  return baseAchUrl;
} 

TtgApi.getBaseUiUrl = function () {
  return baseTtgUiUrl;
}

TtgApi.getBaseApiUrl = function () {
  return baseTtgApiUrl;
} 

export default AchApi;
