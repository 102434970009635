const state = {
  visible: false,
  text: null,
  color: 'success',
  timeout: 10000,
  multiline: false,
};

const mutations = {
  show(state, payload) {
    state.text = payload.text;
    state.color = payload.color ? payload.color : 'success';
    state.multiline = (payload.text.length > 50) ? true : false;

    if (payload.multiline) {
      state.multiline = payload.multiline;
    }

    if (payload.timeout) {
      state.timeout = payload.timeout;
    }

    state.visible = true;
  },
  close(state) {
    state.visible = false;
    state.multiline = false;
    state.timeout = 6000;
    state.text = null;
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  // actions,
  // getters,
};
