import axios from 'axios';
import token from './token.js';
import store from '../store';

// const baseApiUrl = process.env.VUE_APP_API_URL;
// const baseTtgApiUrl = process.env.VUE_APP_TTG_API_URL;

// // @todo: show correct error message on timeout
// const TtgApi = axios.create({
//   baseURL: baseTtgApiUrl,
//   timeout: 10000,
//   headers: {
//     'X-App-Name': 'TTG Customer Portal',
//   }
// });

// TtgApi.interceptors.request.use(function (config) {
//   const id_token = token.getToken();
//   // @todo: check for no token present
//   config.headers.Authorization = id_token;
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

// TtgApi.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx are caught here
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx are caught here
//   if (error.response.status === 401) {
//     const tokenIsExpired = token.isExpired();
//     if (tokenIsExpired) {
//       store.dispatch('app/logUserOut', 'Your token has expired, please login');
//     }
//   } else {
//     // return Promise.reject('test error'+error);
//   }
//   return Promise.reject(error.response.data);
// });

const AwsApi = axios.create({
  // baseURL: 'https://amazon-domain.com/api/',
  timeout: 100000,
  headers: {
    'X-App-Name': 'TTG Customer Portal',
  }
});

export default AwsApi;
