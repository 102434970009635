import jwt from 'jsonwebtoken';

const tokenName = 'id_token';

function getToken() {
  const token = localStorage.getItem(tokenName);
  return token;
}

function isExpired() {
  const token = getToken();
  if(!token) return true;
  const decodedToken = jwt.decode(token);
  if(!decodedToken) return true;
  const expTime = decodedToken.exp ? decodedToken.exp * 1000 : 100000000000000000;
  const tokenIsExpired = (Date.now() - expTime) > 0;
  return tokenIsExpired;
}

function getUserData() {
  const token = getToken();
  const decode = jwt.decode(token);
  const respone = {
    email: decode.Username,
    firstName: decode.Firstname,
    lastName: decode.Lastname,
    phoneNumber: decode.Phonenumber,
  };
  return respone;
}

export default { getToken, isExpired, getUserData };
