import store from '../store';

const appRoutes = [{
  path: '/app',
  meta: {
    requiresAuth: true
  },
  component: () => import('../views/app/index.vue')
    .then(app => app)
    .catch(err => {
      doWeReload();
    }),
  children: [{
      path: '',
      redirect: 'app.dashboard',
    }, {
      path: 'dashboard',
      name: 'app.dashboard',
      props: true,
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "dashboard" */ '../views/app/dashboard/Dashboard.vue')
        .then(dashboardPage => dashboardPage)
        .catch(err => {
          doWeReload();
        }),
    }, {
      path: 'profile',
      name: 'app.profile',
      props: true,
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "profile" */ '../views/app/Profile.vue')
        .then(profilePage => profilePage)
        .catch(err => {
          doWeReload();
        }),
    }, {
      path: 'contact-us',
      name: 'app.contactUs',
      props: true,
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "contactUs" */ '../views/app/ContactUs.vue')
        .then(contactUsPage => contactUsPage)
        .catch(err => {
          doWeReload();
        }),
    }, {
      path: 'faq',
      name: 'app.faq',
      props: true,
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "faq" */ '../views/app/FAQ.vue')
        .then(faq => faq)
        .catch(err => {
          doWeReload();
        }),
    }, {
      path: 'documents-needed',
      name: 'app.documentsNeeded',
      props: true,
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "documentsNeeded" */ '../views/app/documentsNeeded/DocumentsNeeded.vue')
        .then(docsNeededPage => docsNeededPage)
        .catch(err => {
          doWeReload();
        }),
    },
    {
      path: 'view-doc/:docName',
      name: 'app.viewDoc',
      props: true,
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "view-doc" */ '../views/app/ViewDoc.vue')
        .then(faqPage => faqPage)
        .catch(err => {
          doWeReload();
        }),
    }, {
      path: 'registration/:regId',
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "registration" */ '../views/app/registration/Registration.vue')
        .then(registrationPage => registrationPage)
        .catch(err => {
          doWeReload();
        }),
      children: [{
          path: '',
          redirect: 'app.registration.status',
        }, {
          path: 'status',
          name: 'app.registration.status',
          props: true,
          meta: {
            requiresAuth: true
          },
          component: () => import( /* webpackChunkName: "status" */ '../views/app/registration/Status.vue')
            .then(statusPage => statusPage)
            .catch(err => {
              doWeReload();
            }),
        }, {
          path: 'application',
          name: 'app.registration.application',
          props: true,
          meta: {
            requiresAuth: true
          },
          component: () => import( /* webpackChunkName: "application" */ '../views/app/registration/application/Application.vue')
            .then(applicationPage => applicationPage)
            .catch(err => {
              doWeReload();
            }),
        },
        {
          path: 'application-next-steps',
          name: 'app.registration.applicationNextSteps',
          props: true,
          meta: {
            requiresAuth: true
          },
          component: () => import( /* webpackChunkName: "application-next-steps" */ '../views/app/registration/ApplicationNextSteps.vue')
            .then(applicationNextStepsPage => applicationNextStepsPage)
            .catch(err => {
              doWeReload();
            }),
        },
        {
          path: 'cost-breakdown',
          name: 'app.registration.costBreakdown',
          props: true,
          meta: {
            requiresAuth: true
          },
          component: () => import( /* webpackChunkName: "cost-breakdown" */ '../views/app/registration/costBreakdown/CostBreakdown.vue')
            .then(costBreakdownPage => costBreakdownPage)
            .catch(err => {
              doWeReload();
            }),
        },
        
        {
          path: 'payment-details',
          name: 'app.registration.paymentDetails',
          props: true,
          meta: {
            requiresAuth: true
          },
          component: () => import( /* webpackChunkName: "payment-details" */ '../views/app/registration/PaymentDetails.vue')
            .then(paymentPage => paymentPage)
            .catch(err => {
              doWeReload();
            }),
        },
        {
          path: 'ach-confirmation',
          name: 'app.registration.achConfirmation',
          props: true,
          meta: {
            requiresAuth: true
          },
          component: () => import( /* webpackChunkName: "ach-confirmation" */ '../views/app/registration/AchConfirmationModal.vue')
            .then(paymentPage => paymentPage)
            .catch(err => {
              doWeReload();
            }),
        }
      ],
    }
  ],
}, {
  path: '*',
  redirect: '/sign-in'
}];

function doWeReload() {
  // console.log('>>> in doWeReload');
  // const appVersion = VUE_APP_VERSION;
  // console.log('appVersion: ' + appVersion);
  // const avls = localStorage.getItem('appVersion');
  // console.log('appVersion from localStorage: ' + avls);
  // if (avls !== appVersion) {
  //   console.log('>>> TIME TO RELOAD, VERSIONS DO NOT MATCH');
  //   localStorage.setItem('appVersion', appVersion);
  //   window.location.reload(false);
  // } else {
  //   console.log('>>> NOPE, SAME VERSION FOUND');
  // }
  
  // const reload = localStorage.getItem('reload');
  // if(reload === 'true') {
  //   console.log('reloading 1 time');
  //   localStorage.setItem('reload', 'false');
  //   location.reload(true);
  // } else {
  //   console.log('already reloaded, not reloading');
  //   localStorage.setItem('reload', 'true');
  // }
  return false;
}
export default appRoutes;
