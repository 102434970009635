import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Vuetify from '../plugins/vuetify';

import AppRoutes from './routes.app';
import PublicRoutes from './routes.public';

Vue.use(VueRouter);

const routes = PublicRoutes.concat(AppRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch('app/checkUserAuth')
    .then(authed => {
      if (authed) {
        // console.log('user is authed in app route');
        next();
      } else {
        // console.log('user is NOT authed in app route');
        store.dispatch('app/logUserOut', 'You are not authenicated, please log in');
        // next();
      }
      });
  } else {
    return next();
  }
});

// force scroll to top after each page load
router.afterEach((to, from, next) => {
  setTimeout(() => {
    Vuetify.framework.goTo(0);
  });
});

// router.onError(error => {
//   if (/loading chunk \d* failed./i.test(error.message)) {
//     window.location.reload(true);
//     console.log('>> caught chunk error');
//   } else {
//     console.log('caught error', error);
//   }
// })

export default router;
