import CryptoJS from 'crypto-js';

const keyString = '#base64Key#'
const ivString = '#base64IV#';

export default class Crypto {

  constructor() {}

  getEncryptedPwd(password) {

    //Encrypt the Password with Base64
    const key = CryptoJS.enc.Base64.parse(keyString);

    const iv = CryptoJS.enc.Base64.parse(ivString);

    //Implementing the Key and IV and encrypt the password

    const encrypted = String(CryptoJS.AES.encrypt(password, key, {
      iv: iv
    }));

    return encrypted;

  }
}
