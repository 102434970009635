import AchApi from '../../services/api.ach.js';
import TtgApi from '../../services/api.ttg.js';
import IpApi from '../../services/api.ipify.js';

const achMerchantKey = process.env.VUE_APP_ACH_MERCHANT_KEY;
const achProcessorId = process.env.VUE_APP_ACH_PROCESSOR_ID;

const state = {
  loading: false,
  errorMessage: '',
  vehicles: [],
};

const mutations = {};

const actions = {
  getVehicles({ state, commit, rootState }) {
    state.loading = true;
    state.vehicles = [];
    state.errorMessage = null;
    
    return new Promise((resolve, reject) => {
      TtgApi.get(`/cpregistrationbyemail`)
        .then(res => {
          state.loading = false;
          if (!res) throw Error('res is null');
          state.vehicles = res.data;
          // state.regId = res.data.RegistrationID;
          // console.log('res.data', res.data);
          resolve(`Vehicles retreived`);
        }).catch(err => {
          // console.log('in catch of api call in dashboard store', err);
          state.loading = false;
          if(err && err.response && err.response.data) {
            state.errorMessage = err.response.data;
          } else if(err && err.response) {
            state.errorMessage = err.response;
          } else {
            state.errorMessage = err;
          }
          return reject('test');
        });
    });
  },
  submitACH({ dispatch }, passed) {
    return new Promise((resolve, reject) => {
      const payload = {
       
        //merchantKey:"40c6fbd2-490d-4435-a3d7-18c6b7a41127",
        //processorId:"400343",
        //processorId:"321285",
        merchantKey: achMerchantKey,
        processorId: achProcessorId,
        aba:passed.bill.routingNumber,
        dda:passed.bill.accountNumber,
        achName:passed.bill.bankName,
        orderId: passed.achOrderID,
        orderIdIsUnique: true,
        transactionAmount:passed.totalAmountDue,
        accountType:passed.bill.accountType,
        categoryText:"E-CHECK",
        ipAddress:passed.ipAddress,
        ownerName: passed.bill.fullName,
        ownerState: passed.bill.state,
        ownerCity: passed.bill.city,
        ownerCountry:"USA",
        ownerEmail:passed.bill.emailAddress,
        ownerStreet:passed.bill.addressOne,
        ownerStreet2:passed.bill.addressTwo,
        ownerZip:passed.bill.zip
      };


  //AchApi.post(`/Gateway/Transaction/AchDebit`, payload)
  TtgApi.post(`/cpregistration/submitACH`, payload)

        .then(res => {
          const referenceNumber = res.data.data.referenceNumber;

          const payload2 = {
            PaymentType: passed.paymentType,
            ttgCheckNo:res.data.data.referenceNumber,
          };

          TtgApi.put(`cpregistration/PaymentInfo/${passed.regId}`, payload2)
          .then(res => {
            resolve('PaymentInfo updated');
          })
          .catch(err => {
            console.log('error setting PaymentInfo', err);
            reject('error setting PaymentInfo');
          });

          resolve({referenceNumber, msg: `Reference #: ${referenceNumber}.`});


        }).catch(err => {

          reject(`Sorry, we were unable to process your payment at this time.`);

         // store.commit('snackmsg/show', { text: err, color: 'error' });
         // reject('Error submitting Payment');
          //VueRouter.push({ name: 'app.dashboard' });

          var errorMessage="";

          console.log("error response is " + err);
       
          if (err=="Error: Network Error") {
            console.log("CORS issue");
            reject('Network error. We apologize for the technical difficulty with Pay Now. To reset the Pay Now function, please sign out, sign back in, and re-enter your payment . The system does not allow for duplicate payments. If you continue having any issues, please call our office at 214.997.6005 for assistance.');
          }
          // else if (err.response.data.validationHasFailed) {
          //    errorMessage=err.response.data.validationFailures[0].message;
          //    console.log("validation error is" + JSON.stringify(err.response.data.validationFailures[0].message));
          // }
          // else if (err.response.data.isError){
          //   errorMessage=err.response.data.errorMessages[0];
          //   console.log("error is" + JSON.stringify(err.response.data.errorMessages[0]));
          // } 

         else if (err.response.data.validationHasFailed) {
            errorMessage=err.data.validationFailures[0].message;

            if (errorMessage="Order Id is not unique")
              errorMessage="Thank you for submitting your payment. This transaction takes 1-2 business days to process. You will receive an email to confirm completion of this transaction and receipt of funds at The Title Girl.  Thank you for your patience. No further action regarding payment is required from you at this time. "

              console.log("validation error is" + errorMessage);

            //console.log("validation error is" + JSON.stringify(err.data.validationFailures[0].message));
         }
         else if (err.data.isError){
           errorMessage=err.data.errorMessages[0];
           console.log("error is" + JSON.stringify(err.data.errorMessages[0]));
         } 
          else errorMessage="Unknown error"
          reject(`Sorry, we were unable to process your payment at this time. Reason: ${errorMessage}.`);
        });
    });
  },
  
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
