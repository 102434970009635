import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: colors.red.lighten4, // #E53935
        // primary: colors.deepOrange.darken3,
        primary: '#181616',
        secondary: '#feeeb4',
        
        // secondary: colors.teal.darken1, // #FFCDD2
        third: colors.teal.accent1,
        white: colors.shades.white,
        accent: colors.indigo.base, // #3F51B5
        indigo1: colors.lightBlue, // colors.deepOrange.lighten5,
        orange1: colors.deepOrange.lighten3,
        yellow1: colors.lime,

        mainBlack: "#181616",
        mainBiege: '#feeeb4',
        mainRed: '#E4002B',
        mainWhite: '#ffffff',
        mainYellow: '#FFFF00',

        mainBackground: '#ffffff',
        mainTextColor: '#181616',
        regItemBackground: '#f3f3f3',
        cardBackground: '#fbfbfb',
      },
      dark: {
        // primary: colors.red.lighten4, // #E53935
        // primary: colors.deepOrange.darken3,
        primary: '#ffffff',
        // secondary: '#feeeb4',
        // primary: colors.purple,
        // secondary: colors.grey.darken3, //#373737
        // accent: colors.shades.black,
        // error: colors.red.accent3,
        // background: colors.indigo.lighten5, // Not automatically applied
        
        // secondary: colors.teal.darken1, // #FFCDD2
        third: colors.teal.accent1,
        white: colors.shades.white,
        accent: colors.indigo.base, // #3F51B5
        indigo1: colors.lightBlue, // colors.deepOrange.lighten5,
        orange1: colors.deepOrange.lighten3,
        yellow1: colors.lime,

        mainBlack: "#181616",
        mainBiege: '#feeeb4',
        mainRed: '#E4002B',
        mainWhite: '#ffffff',
        mainYellow: '#FFFF00',

        // #292929
        mainBackground: '#121212',//'#181616',//#121212
        mainTextColor: '#ffffff',
        regItemBackground: '#292929',
        cardBackground: '#2b2b2b',
      },
    },
  },
});
