<template>
  <v-app>
    <SnackMsg></SnackMsg>
    <TopMenu></TopMenu>

    <v-main>
      <router-view></router-view>
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
    TopMenu: () => import('@/components/global/TopMenu.vue'),
    Footer: () => import('@/components/global/Footer.vue'),
    SnackMsg: () => import('@/components/global/SnackMsg.vue'),
  },
  data: () => ({}),
};
</script>

<style>
@import './App.css';
</style>
