import axios from 'axios';
import token from './token.js';
import store from '../store';

/**
 * Documentation here https://www.ipify.org/
 */

const AwsApi = axios.create({
  baseURL: 'https://api.ipify.org',
  timeout: 10000,
});

export default AwsApi;
