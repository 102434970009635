import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import DateFilter from './filters/date.js';
import CurrencyFilter from './filters/currency.js';
import './components/global/index.js';

//import Particles from "particles.vue";


Vue.config.productionTip = false;

Vue.filter('date', DateFilter); // register filter globally
Vue.filter('currency', CurrencyFilter);

const app = new Vue({
  store,
  router,
  vuetify,
 // Particles,
  render: (h) => h(App)
}).$mount('#app');
